.navbar {
    justify-content: space-around;
    align-items: center;
    background: rgb(225, 225, 225);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.475) 0%, rgba(225, 225, 225, 1) 70%);
    padding-bottom: 5px;
}

.nav-left {
    flex: 3;
}

.primary-nav {
    list-style-type: none;
    margin-top: 1rem;
    margin-bottom: 5px !important;
    margin-right: 3rem;
    padding-inline: clamp(3rem, 3vw, 5rem);
    padding-block: 0.5rem;
    --gap: clamp(0.25rem, 5vw, 2rem);
    --underline-gap: 0.25rem;
    justify-content: flex-start;
    border-radius: 30px;
}

.mobile-nav-toggle {
    display: none;
}

.mobile-nav-toggle:hover {
    background-color: hsl(var(--clr-yellow));
}

@media (max-width: 69em) {
    .primary-nav {
        position: fixed;
        inset: 0 0 0 30%;
        z-index: 999;
        flex-direction: column;
        padding: min(20vh, 10rem) 2em;
        margin-top: 0;
        margin-bottom: 0 !important;
        transform: translateX(100%);
        transition: transform 350ms ease-out;
        margin-right: 0;
    }

    .primary-nav[data-visible="true"] {
        transform: translateX(0%);
    }

    @supports (backdrop-filter: blur(1rem)) {
        .primary-nav[data-visible="true"] {
            background: hsl(var(--clr-light) / 0.1);
            backdrop-filter: blur(1.5rem);
        }
    }

    .mobile-nav-toggle {
        display: block;
        position: fixed;
        width: 3rem;
        height: 3rem;
        object-fit: scale-down;
        top: 2rem;
        right: 2rem;
        z-index: 9999;
        border: 0;
        padding: 0.5rem;
        border-radius: 50%;
    }
}

.primary-nav a {
    padding: var(--underline-gap, 1rem) 0;
    text-decoration: none;
}
.primary-nav span {
    font-weight: 700;
    margin-right: 0.5rem;
}

.nav-line {
    display: block;
    position: relative;
    height: 1px;
    width: 100%;
    margin-right: -2.5rem;
    z-index: 2000;
    background: hsl(var(--clr-white) / 0.25);
}

.logo {
    margin: 0.25rem clamp(1.5rem, 5vw, 3.5rem);
    border-radius: 10px;
}

.nav-anchor {
    display: block;
    width: 100%;
}
