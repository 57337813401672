.services section {
    padding-block: var(--section-padding-block);
}

.full-width-split-screen > :not(img) {
    padding-inline: var(--container-padding);
    /* padding-block: var(--section-padding-block); */
}

.full-width-split-screen {
    padding-block: 0 !important;
    margin-bottom: 2rem;
    width: 100%;
    height: 27rem;
    display: grid;
    align-items: center;
}

.full-width-split-screen:nth-child(even) button:hover {
    background-color: hsl(var(--clr-blue));
    color: hsl(var(--clr-white));
}

.full-width-split-screen:nth-child(odd) button:hover {
    background-color: hsl(var(--clr-yellow));
    color: hsl(var(--clr-white));
}

.full-width-split-screen button {
    border: none;
    text-decoration: none;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 25px;
    margin-top: 1rem;
    cursor: pointer;
}

.full-width-split-screen > img {
    display: none;
}

@media (min-width: 786px) {
    .full-width-split-screen {
        height: 40rem;
        grid-template-columns:
            minmax(var(--container-padding), 1fr)
            minmax(0, calc(var(--container-max-width) / 2))
            minmax(0, calc(var(--container-max-width) / 2))
            minmax(var(--container-padding), 1fr);
        position: relative;
    }

    .full-width-split-screen > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    .full-width-split-screen > img:first-child {
        grid-column: 1/3;
    }

    .full-width-split-screen > img:last-child {
        grid-column: 3/-1;
    }

    .full-width-split-screen > :not(img) {
        display: grid;
        align-content: center;
        justify-items: start;
    }

    .full-width-split-screen > :not(img):first-child {
        grid-column: 2/3;
        padding-left: 0;
        padding-right: clamp(1rem, 5vw, 3rem);
    }

    .full-width-split-screen > :not(img):last-child {
        grid-column: 3/4;
        padding-right: 0;
        padding-left: clamp(1rem, 5vw, 3rem);
    }

    .full-width-split-screen > :not(img)::before {
        content: "";
        position: absolute;
        background-color: inherit;
        inset: 0;
        grid-column: 4 / 5;
    }

    .full-width-split-screen > :not(img):first-child:before {
        grid-column: 1 / 2;
    }

    .full-width-split-screen > :not(img):last-child:before {
        grid-column: 4 / 5;
    }
}
