@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* ------------------- */
/* Custom properties   */
/* ------------------- */
:root {
    /* colors */
    --clr-dark: 230 35% 7%;
    --clr-light: 231 77% 90%;
    --clr-white: 0 0% 100%;
    --clr-blue: 223 70% 44%;
    --clr-yellow: 48 76% 49%;
    --clr-gray: 0 0% 40%;
    --clr-light-gray: 225 18.2% 95.7%;
    --clr-light-dark: 225 20% 20%;

    /* font sizes */
    --fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
    --fs-800: 3.5rem;
    --fs-700: 1.5rem;
    --fs-600: 1rem;
    --fs-500: 1rem;
    --fs-400: 0.9375rem;
    --fs-300: 1rem;
    --fs-200: 0.875rem;

    /* font families */
    --ff-sans-poppins: "Poppins", serif;

    --container-padding: 2rem;
    --container-max-width: 80rem;
    --section-padding-block: 2rem;
}

@media (min-width: 35em) {
    :root {
        /* font sizes */
        --fs-800: 5rem;
        --fs-700: 2.5rem;
        --fs-600: 1.5rem;
        --fs-500: 1.25rem;
        --fs-400: 1rem;
    }
}

@media (min-width: 45em) {
    :root {
        /* font sizes */
        --fs-800: 6.25rem;
        --fs-700: 3.5rem;
        --fs-600: 2rem;
        --fs-500: 1.75rem;
        --fs-400: 1.125rem;
    }
}

/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
picture {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
}

/* Set up the body */
body {
    font-family: var(--ff-sans-poppins);
    font-size: var(--fs-400);
    color: hsl(var(--clr-light-dark));
    /* background-image: url("./assets/polygon-lumninary.svg"); */
    /* background-size: cover; */
    /* background-position: center center; */
    background-color: white;
    line-height: 1.5;
    min-height: 100vh;
    overflow-x: hidden;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
}

:where(img, picture):not([hidden]) {
    display: block;
}

/* Make form elements easier to work with */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove animations for people who've turn them off */
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/* ------------------- */
/* Utilities classes   */
/* ------------------- */

/* general */
.flex {
    display: flex;
    gap: var(--gap, 1rem);
}

.grid {
    display: grid;
    gap: var(--gap, 1rem);
}

.d-block {
    display: block;
}

.flow > *:where(:not(:first-child)) {
    margin-top: var(--flow-space, 1rem);
}

.container {
    padding-inline: var(--container-padding);
    margin-inline: auto;
    max-width: var(--container-max-width);
}

.grid-container {
    text-align: center;
    display: grid;
    place-items: center;
    padding-inline: 1rem;
    padding-bottom: 4rem;
}

.grid-container p.text-accent {
    max-width: 50ch;
}

@media (min-width: 45em) {
    .grid-container {
        text-align: left;
        column-gap: var(--container-gap, 2rem);
        grid-template-columns:
            minmax(1rem, 1fr)
            repeat(2, minmax(0, 40rem))
            minmax(1rem, 1fr);
    }

    .grid-container p.text-accent {
        line-height: 2;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.box {
    background: lightblue;
    padding: 3rem;
}

/* colors */
.bg-dark {
    background-color: hsl(var(--clr-dark));
}
.bg-accent {
    background-color: hsl(var(--clr-light));
}
.bg-white {
    background-color: hsl(var(--clr-white));
}
.bg-blue {
    background-color: hsl(var(--clr-blue));
}
.bg-yellow {
    background-color: hsl(var(--clr-yellow));
}
.bg-light-gray {
    background-color: hsl(var(--clr-light-gray));
}

.text-dark {
    color: hsl(var(--clr-dark));
}
.text-light-dark {
    color: hsl(var(--clr-light-dark));
}
.text-accent {
    color: hsl(var(--clr-light));
}
.text-white {
    color: hsl(var(--clr-white));
}
.text-gray {
    color: hsl(var(--clr-gray));
}
.text-blue {
    color: hsl(var(--clr-blue));
}

/* typography */
.ff-serif {
    font-family: var(--ff-serif);
}
.ff-sans-cond {
    font-family: var(--ff-sans-cond);
}
.ff-sans-normal {
    font-family: var(--ff-sans-normal);
}

.letter-spacing-1 {
    letter-spacing: 4.75px;
}
.letter-spacing-2 {
    letter-spacing: 2.75px;
}
.letter-spacing-3 {
    letter-spacing: 2.35px;
}

.uppercase {
    text-transform: uppercase;
}

.fs-900 {
    font-size: var(--fs-900);
}
.fs-800 {
    font-size: var(--fs-800);
}
.fs-700 {
    font-size: var(--fs-700);
}
.fs-600 {
    font-size: var(--fs-600);
}
.fs-500 {
    font-size: var(--fs-500);
}
.fs-400 {
    font-size: var(--fs-400);
}
.fs-300 {
    font-size: var(--fs-300);
}
.fs-200 {
    font-size: var(--fs-200);
}

.fs-900,
.fs-800,
.fs-700,
.fs-600 {
    line-height: 1.1;
}

.underline-indicators {
    border: 0;
    border-bottom: 0.2rem solid hsl(var(--clr-white));
    padding: var(--underline-gap, 1rem) 0;
}

.underline-indicators:hover {
    border-bottom: 0.2rem solid hsl(var(--clr-yellow));
}

.underline-indicators[data-active="true"] {
    border-bottom: 0.2rem solid hsl(var(--clr-yellow));
}

.blue-box-shadow {
    box-shadow: 0px 5px 10px 0px hsl(var(--clr-blue) / 0.5);
}

.dark-box-shadow {
    box-shadow: 0px 5px 10px 0px hsl(var(--clr-dark) / 0.5);
}

.page-title {
    margin-bottom: 2rem;
    border-bottom: 1px solid hsl(var(--clr-gray));
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}
