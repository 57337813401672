.slider {
    background-image: url("../../assets/home/main_carousel/3.jpg");
    background-image: image-set("../../assets/home/main_carousel/3.webp", "../../assets/home/main_carousel/3.jpg");
    background-image: -webkit-image-set("../../assets/home/main_carousel/3.webp", "../../assets/home/main_carousel/3.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 40vh;
}

.slider-text {
    padding-inline: 2rem;
    padding-block: 2rem;
    font-weight: 800;
}

@media (min-width: 35em) {
    .slider-text {
        font-size: var(--fs-600);
    }
}

@media only screen and (min-width: 69em) {
    .slider-text {
        padding-inline: 5rem;
        padding-block: 5rem;
        font-size: var(--fs-700);
    }

    .slider {
        height: 70vh;
    }
}

@media only screen and (min-width: 69em) and (max-height: 22em) {
    .slider-text {
        padding-inline: 2rem;
        padding-block: 2rem;
    }
}

.home-services {
    justify-content: space-around;
    padding-bottom: 2rem;
    flex-direction: column;
    align-items: center;
}

.home-service {
    width: 25rem;
    padding-bottom: 1rem;
    border-radius: 25px;
}

.industries-heading {
    font-weight: 500;
}

.industries {
    border-top: 2px solid hsl(var(--clr-light));
    border-bottom: 2px solid hsl(var(--clr-light));
}

.services-industries {
    padding-inline: 2rem;
    padding-block: 0.5rem;
}

@media only screen and (min-width: 69em) {
    .services-industries {
        padding: 2rem;
    }
}

.home-service-body {
    margin: 0.75rem;
    padding: 0.5rem;
    padding-bottom: 1.25rem;
}

.home-service-body-header {
    margin: 1rem;
    padding-inline: 0.5rem;
    border-left: 5px solid hsl(var(--clr-yellow));
}

.home-service:hover {
    background-color: hsl(var(--clr-yellow));
}

.home-service:hover .home-service-body-header {
    background-color: hsl(var(--clr-yellow));
    border-left: 5px solid hsl(var(--clr-blue));
}

.home-service-image {
    height: 20rem;
    width: 25rem;
    object-fit: cover;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.home-service-title {
    margin-block: 1rem;
    font-weight: 500;
}

.service-read-more {
    border: none;
    text-decoration: none;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 25px;
    margin-left: 1.25rem;
}

.service-read-more:hover {
    background-color: hsl(var(--clr-blue));
    color: hsl(var(--clr-white));
}

.slider-items {
    padding: 1rem;
}

.slider-item {
    text-align: center;
}

.slider-item-text {
    margin-top: 0.25rem;
}

.carousel-image {
    height: 150px;
}

.owl-stage-outer {
    border-radius: 5px;
    padding-block: 0.5rem;
    box-shadow: 0px 5px 10px 0px hsl(var(--clr-blue) / 0.5);
    background-color: hsl(var(--clr-light-gray));
}

.owl-prev,
.owl-next {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    display: block !important;
    background-color: hsl(var(--clr-yellow)) !important;
    color: hsl(var(--clr-white)) !important;
}
.owl-prev {
    left: -20px;
}

.owl-next {
    right: -20px;
}

.our-services-module {
    padding: 1rem;
}

.our-services {
    justify-content: center;
}

.our-service {
    flex-direction: column;
    height: 20rem;
    width: 20rem;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding-inline: 1rem;
}

.our-service:hover {
    box-shadow: 0px 5px 10px 0px hsl(var(--clr-blue) / 0.5);
    background-color: hsl(var(--clr-light-gray));
}

.our-service:hover .service-icons {
    color: hsl(var(--clr-yellow));
}

.our-service:hover .our-service-title {
    border-bottom: 4px solid hsl(var(--clr-yellow));
}

.our-service-body {
    text-align: center;
}

.our-services-heading {
    text-align: center;
    padding-bottom: 1rem;
}

.our-service-item {
    flex-direction: column;
}

@media only screen and (min-width: 69em) {
    .home-services {
        flex-direction: row;
    }

    .home-service {
        margin-top: -100px;
    }
}

@media only screen and (min-width: 43em) {
    .our-service-item {
        flex-direction: row;
    }
}
