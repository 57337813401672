.contact-page {
    padding: 0;
}

.contact-map-location {
    width: 100vw;
    height: 40vh;
    border: 0;
    z-index: 1;
    display: block;
}

.contact-form-heading {
    padding-block: 2rem;
}

.contact-form {
    border-radius: 25px;
    padding-inline: 1rem;
    padding-bottom: 1rem;
    margin-bottom: -50px;
    z-index: 99;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 768px) {
    .contact-form {
        padding-inline: 5rem;
        padding-bottom: 2rem;
    }
}

.contact-phone,
.contact-mail-address,
.contact-office-address {
    align-items: center;
}

.contact-icon {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    line-height: 15px;
    padding: 1rem;
}

@media only screen and (min-width: 768px) {
    .contact-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.contact-inputs {
    align-items: center;
    justify-content: center;
}

.input-message {
    width: 100%;
    resize: none;
    height: 10rem;
}

.input-first-name,
.input-last-name,
.input-email,
.input-phone,
.input-message {
    width: 100%;
    border-radius: 25px;
    padding-block: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 2.35rem;
    border: 1px solid hsl(var(--clr-blue));
}

.contact-form-div {
    flex-direction: column;
}

.contact-input {
    flex-direction: column;
}

.contact-input-grid {
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 69em) {
    .contact-form-div {
        flex-direction: row;
    }

    .contact-input {
        flex-direction: row;
    }

    .input-message {
        width: 100%;
        resize: none;
        height: 10rem;
    }
}

.contact-information {
    max-width: 36rem;
}

.contact-info:hover .contact-icon {
    background-color: hsl(var(--clr-yellow));
}

.contact-info:hover .contact-info-content {
    background-color: hsl(var(--clr-yellow) / 0.25);
    border-radius: 25px;
}

.contact-info-content {
    padding-inline: 0.6rem;
}

.contact-info-heading {
    font-weight: 500;
}

.contact-info-content > :not(.contact-info-heading) {
    font-size: var(--fs-300);
}

@media only screen and (min-width: 768px) {
    .contact-info-content {
        padding-inline: 1rem;
    }
    .contact-information {
        min-width: 36rem;
    }
}

.input-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

.input-icon {
    padding-block: 0.75rem;
    padding-inline: 1rem;
    margin-left: -50px;
}

.input-submit {
    border: none;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 25px;
    cursor: pointer;
}

.input-submit-div {
    margin: auto;
}

@media only screen and (min-width: 768px) {
    .input-submit-div {
        margin: none;
    }
}

.input-submit:hover {
    background-color: hsl(var(--clr-yellow));
}
