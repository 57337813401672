.footer {
    justify-content: space-around;
    padding-block: 0.5rem;
    width: 100vw;
}

.logo-footer {
    align-items: center;
    flex-direction: column;
}

.contact-info-title {
    font-weight: 600;
}

.contact-span {
    font-weight: 600;
}

.footer-company {
    padding-inline: 1rem;
    font-size: var(--fs-300);
}

.footer-company-text {
    font-size: 0.75rem;
    font-weight: 600;
}

.footer-company-description {
    font-size: 0.75rem;
}

.footer-right {
    flex-direction: column;
}

.office-location {
    border: 0;
    width: 65vw;
}

@media only screen and (min-width: 69em) {.footer {
    padding-block: 2rem;
}

    .logo-footer {
        flex-direction: row;
    }

    .footer-company {
        padding-inline: none;
    }

    .footer-company-text {
        font-size: var(--fs-400);
        font-weight: 600;
    }

    .footer-company-description {
        font-size: var(--fs-200);
    }

    .footer-right {
        flex-direction: row;
    }

    .office-location {
        width: 35vw;
    }
}