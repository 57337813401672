.service-detail {
    flex-direction: column;
}

.service-sidebar {
    list-style-type: none;
    padding: 0;
    margin-top: 0.5rem;
}

.service-sidebar li {
    text-align: center;
    margin-bottom: 0.5rem;
    padding-inline: 1rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    cursor: pointer;
}

.service-points {
    list-style-type: "* ";
    padding-left: 2.5rem;
}

.service-sidebar li:hover,
.service-sidebar .active {
    background-color: hsl(var(--clr-yellow));
    color: hsl(var(--clr-light-dark));
}

.service-points {
    max-width: 45rem;
}

.service-detail-back {
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 1rem;
}

@media only screen and (min-width: 68em) {
    .service-detail {
        flex-direction: row;
        --gap: 3rem;
    }

    .service-sidebar li {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        text-align: left;
        width: 20rem;
    }
}
